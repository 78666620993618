

<template>
  <transition name="fade">
  <div class="settings">
   <main-nav />
    <main-titlebar myclass="yellowBar" icon="fas fa-clock" />


      <div class="grid-box-vscale">
        <p>Beheer hier uw instellingen</p>
      </div>

      <div class="row-line">Persoonlijk<hr /></div>    

      <div class="trip-view-row">
        <div class="trip-view-col">
          <router-link to="/my/profile">
            <div class="default-button"><i class="fa-solid fa-address-card"></i> Profiel</div>
          </router-link>
        </div>
      </div>
      <div class="trip-view-row">
        <div class="trip-view-col">
          <router-link to="/my/preferences">
            <div class="default-button"><i class="fa-solid fa-list-check"></i> Voorkeuren</div>
          </router-link>
        </div>
      </div>  

       <div class="row-line">Sociaal<hr /></div>    

      <div class="trip-view-row">
        <div class="trip-view-col">
          <router-link to="/circles">
            <div class="default-button"><i class="fa-solid fa-circle-notch"></i> Cirkels</div>
          </router-link>
        </div>
      </div>

     <div class="trip-view-row">
        <div class="trip-view-col">
          <router-link to="/contacts">
            <div class="default-button"><i class="fa-solid fa-address-book"></i> Contacten</div>
          </router-link>
        </div>
      </div>

      <div class="row-line">Overig<hr /></div>    

      <div class="trip-view-row">
        <div class="trip-view-col">
          <router-link to="/logout">
            <div class="default-button"><i class="fa-solid fa-right-from-bracket"></i> Afmelden</div>
          </router-link>
        </div>
      </div>



  </div>
  </transition>
</template>
<script>
export default {
  name:'settings',
  setup() {

  },
  mounted() {

  },
  computed: {

  },
  
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,

  }
}
</script>

<style scoped>
/*transition the opacity when the element enters and leaves the DOM*/
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s; 
}

/*set opacity to 0 at element's entrance and exit*/
.fade-enter, .fade-leave-to {
  opacity: 0; 
}
</style>